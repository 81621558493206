import React, {useState} from "react"
import { Carousel } from "react-bootstrap"

import Slide1 from "images/dosing-carousel-1.png"
import Slide2 from "images/dosing-carousel-2.png"
import Slide3 from "images/dosing-carousel-3.png"
import Slide4 from "images/dosing-carousel-4.png"

import PrevIcon from "images/carousel-prev-icon.svg"
import NextIcon from "images/carousel-next-icon.svg"

import styles from "./DosingCarousel.module.scss"

const DosingCarousel = () => {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className={styles.container}>
      <Carousel
        nextIcon={index < 3 && <img alt="Next Slide" src={NextIcon} />}
        prevIcon={index > 0 && <img alt="Previous Slide" src={PrevIcon} />}
        onSelect={handleSelect}
        interval={null}
        className={styles.dosingCarousel}
      >
        <Carousel.Item className={styles.item}>
          <img className="d-block w-100" alt="Gradual dose escalation chart" src={Slide1} />
          <Carousel.Caption className={styles.caption}>
            <h3 className={styles.captionHeader}>
              With the PALFORZIA treatment pathway, patients receive gradually
              increasing doses of PALFORZIA over a 6-month period, then continue
              with daily Maintenance dosing.
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className={styles.item}>
          <img className="d-block w-100" alt="Initial Dose Escalation chart" src={Slide2} />
          <Carousel.Caption className={styles.caption}>
            <h3 className={styles.captionHeader}>
              Patients who tolerate at least the 3-mg single dose of PALFORZIA
              during Initial Dose Escalation should return to the office,
              preferably the next day for initiation of Up-Dosing.
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className={styles.item}>
          <img className="d-block w-100" alt="Up-Dosing chart" src={Slide3} />
          <Carousel.Caption className={styles.caption}>
            <h3 className={styles.captionHeader}>
              {
                "Each up-dose is conducted at the allergist’s office at approximately 2-week intervals.\nIn between office visits, patients take remaining doses at home, once daily."
              }
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className={styles.item}>
          <img className="d-block w-100" alt="Maintenance dosing illustration" src={Slide4} />
          <Carousel.Caption className={styles.caption}>
            <h3 className={styles.captionHeader}>
              Periodic visits give the physician an opportunity to reinforce
              adherence and determine whether continuing with Maintenance dosing
              is appropriate.{" "}
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default DosingCarousel
