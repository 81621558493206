import React from "react"
import { Container } from "react-bootstrap"

import { graphql } from "gatsby"

import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"
import { useDownloadBrochureMenuData } from "hooks/contentful/useDownloadBrochureMenuData"

import Layout from "components/Layout"
import SEO from "components/Seo"
import PageTitle from "components/PageTitle/PageTitle"
import DosingCarousel from "components/DosingCarousel/DosingCarousel"
import DosingSteps from "components/DosingSteps/DosingSteps"
import Caption from "components/Caption/Caption"
import CaptionCTA from "components/CaptionCTA/CaptionCTA"

import Step1 from "images/dosing-mobile-1.svg"
import Step2 from "images/dosing-mobile-2.svg"
import Step3 from "images/dosing-mobile-3.svg"
import DosageLevels from "images/dosage-levels.png"
import DosageLevelsMobile from "images/dosage-levels-mobile.png"
import DosageCalendar from "images/dosage-calendar.png"

import "../styles/main.scss"
import styles from "../styles/pages/dosing.module.scss"

const REFERENCES_HEADER = "References"
const REFERENCES = [
  "PALFORZIA [package insert]. Brisbane, CA: Aimmune Therapeutics, Inc.",
]

const DOSING_STEPS = [
  {
    image: Step1,
    header: "Initial Dose Escalation",
    topics: [
      "Occurs on the first day of PALFORZIA dosing, which takes place at the clinic Lasts about 4 hours",
      "Consists of 5 dose escalations",
      "Each dose is separated by a 20- to 30-minute observation period",
      "Patients must be observed for at least 60 minutes after the last dose",
    ],
  },
  {
    image: Step2,
    header: "Up-Dosing",
    topics: [
      "Starts within 4 days of Initial Dose Escalation—ideally the day after",
      "Biweekly Up-Dosing at the clinic; patients take once-daily dosing at home between clinic visits",
      "Takes around 6 months",
      "Consists of 11 Up-Dosing levels",
      "Requires in-clinic dosing for first dose of each dosing level",
      "Patients must be observed for at least 60 minutes after in-clinic dosing",
    ],
  },
  {
    image: Step3,
    header: "Maintenance Dosing",
    topics: [
      "Starts after all Up-Dosing levels are complete",
      "Follow-up clinic visit should be scheduled at the physician's discretion",
      "Continued daily Maintenance dosing is required to maintain the treatment effect",
    ],
  },
]

const Dosing = ({ data }) => {
  const captionCTA = useDownloadBrochureMenuData()

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Layout referencesHeader={REFERENCES_HEADER} references={REFERENCES}>
        <SEO title={data.page.title} description={data.page.description} />
        <main className={styles.dosing}>
          <PageTitle title={"Dosing & Administration"}></PageTitle>
          <Container fluid as={"section"} className={styles.dosingSection}>
            <Container>
              <h2 className={styles.sectionHeader}>
                Treatment with PALFORZIA is administered in 3 sequential phases: 
                Initial Dose Escalation, Up-Dosing, and Maintenance
                <sup className={styles.references}>1</sup>
              </h2>
              <p className={styles.body}>
                PALFORZIA patients are exposed to gradually increasing amounts
                of peanut allergen to help them decrease sensitivity over time to small amounts of 
                peanuts that may be hidden in foods. Treatment begins with a 0.5-mg dose taken during Initial
                Dose Escalation and culminates with a 300-mg dose, which is
                taken daily during Maintenance dosing.
              </p>
              <DosingCarousel />
              <DosingSteps steps={DOSING_STEPS} />
              <img className={styles.dosageLevels} alt="Gradually increasing dosage by milligram illustration" src={DosageLevels} />
              <img
                className={styles.dosageLevelsMobile}
                alt="Gradually increasing dosage by milligram illustration"
                src={DosageLevelsMobile}
              />
              <p className={styles.dosageLevelsTopic}>
                Maintenance dose
              </p>
              <Caption>
              Temporary dose modification may be required for patients who experience allergic reactions during Up-Dosing or Maintenance, for patients who miss doses, or for practical reasons of patient management. Use clinical judgment to determine the best course of action, which can include maintaining the dose level for longer than 2 weeks, reducing, withholding, or discontinuing PALFORZIA doses.
                <sup className={styles.references}>1</sup>
              </Caption>
            </Container>
          </Container>
          <CaptionCTA {...captionCTA} />
          <Container fluid as={"section"} className={styles.approachSection}>
            <Container>
              <div className={styles.approach}>
                <div className={styles.approachBody}>
                  <h2 className={styles.approachHeader}>
                    A standardized approach
                  </h2>
                  <h3 className={styles.approachTopicHeader}>
                    Daily preparations of PALFORZIA for oral administration
                  </h3>
                  <ul className={styles.approachTopics}>
                    <li className={styles.approachTopic}>
                      PALFORZIA should be mixed well with a few spoonfuls of
                      refrigerated or room-temperature semisolid food (eg,
                      applesauce, yogurt, pudding), and consumed in its
                      entirety. Patients do not swallow capsule(s) or inhale
                      the powder
                    </li>
                  </ul>
                  <h3 className={styles.approachTopicHeader}>
                    Controlled, in-office Up-Dosing enables collaboration with
                    patients
                  </h3>
                  <ul className={styles.approachTopics}>
                    <li className={styles.approachTopic}>
                      In-office visits for the first dose of Up-Dosing provide 
                      opportunities for patients to discuss their progress
                    </li>
                    <li className={styles.approachTopic}>
                      Daily oral dosing helps patients know their dosage, standardizes 
                      PALFORZIA protocol, and reinforces patient adherence
                    </li>
                  </ul>
                </div>
                <img
                  className={styles.approachImage}
                  alt="Daily dose calendar icon"
                  src={DosageCalendar}
                />
              </div>
            </Container>
          </Container>
        </main>
      </Layout>
    </StateProvider>
  )
}

export default Dosing

export const query = graphql`
  query {
    page: contentfulHcpPage(label: { eq: "Dosing" }) {
      label
      url
      title
      description
    }
  }
`
