import React from "react"

import styles from "./DosingSteps.module.scss"

const Step = ({ image, header, topics, index }) => {
  return (
    <div className={styles.step}>
      <img className={styles.image} alt="" src={image} />
      <h3 className={styles.header}>{`${index}. ${header}`}</h3>
      <ul className={styles.topics}>
        {topics.map((topic) => {
          return (
            <li key={topic} className={styles.topic}>
              {topic}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const DosingSteps = ({ steps = [] }) => {
  return (
    <div className={styles.container}>
      <div className={styles.dosingSteps}>
        {steps.map((step, index) => (
          <Step key={index} {...step} index={index + 1}></Step>
        ))}
      </div>
    </div>
  )
}

export default DosingSteps
